<template>
	<el-container id="container" style="min-height: 1507px;" direction="vertical">
		<div class="block">
			<img src="../../../assets/image/PC.png" alt="" style="position: absolute; top: 80px; left: 938px;">
			<div class="text1">
				壹本账管理系统
			</div>
			<div class="text2">
				PC端
			</div>
			<div class="text3">
				只要录入2张单，系统就能自动生成38张管理报表。
			</div>
			
			<div class="text4" style="display: flex; align-items: center; position: absolute; left: 210px; top: 260px;">
				<img src="../../../assets/image/true8.png" style="width: 29px; height: 26px;">
				<div style="margin-left: 9px;">更及时：实时生成38张管理报表，不需要等待专门的账务处理。</div>
			</div>
			<div class="text4" style="display: flex; align-items: center; position: absolute; left: 210px; top: 301px;">
				<img src="../../../assets/image/true8.png" style="width: 29px; height: 26px;">
				<div style="margin-left: 9px;">更易懂：报表通俗易懂，没有专门术语，不用学财务理论。</div>
			</div>
			<div class="text4" style="display: flex; align-items: center; position: absolute; left: 210px; top: 342px;">
				<img src="../../../assets/image/true8.png" style="width: 29px; height: 26px;">
				<div style="margin-left: 9px;">更精细：多维度组合分析；阿米巴核算；多项目核算。</div>
			</div>
			
			<div class="box1"  @click="buttonclick('local')">
				<div class="text5">
					局域网版
				</div>
				<div class="text6">
					（可试用版本） 
				</div>
			</div>
			
			<div class="box1" style="left: 536px;" @click="buttonclick('cloud')">
				<div class="text5">
					云端版
				</div>
				<div class="text6">
					（非试用版本） 
				</div>
			</div>
			
			<div class="box2"></div>
			
			<div class="box3">
				<div class="text3" style="top: 39px; left: 37px;">
					软件安装注意事项
				</div>
				<div class="text4" style="position:absolute; top: 78px; left: 39px;">
					① 安装前，请先退出杀毒软件，避免造成某些控件无法注册；安装后，可以正常打开杀毒软件。
				</div>
				<div class="text4" style="position:absolute; top: 108px; left: 39px; display: flex;">
					② 安装时，如提示缺少.Net Framework运行环境，<div style="color: #FFD800;"><a style="color: #FFD800;" href="https://download.s21i.faiusr.com/14807269/0/0/ABUIABBLGAAgsrqIlQYo8vOalwE.exe?f=ndp472-devpack-enu.exe&v=1654791606">请点此下载。</a></div>
				</div>
				<div class="button1" @mouseenter="buttonEnter()" @mouseleave="buttonLeave()">
					{{buttontext}}
				</div>
			</div>
		</div>
		
		<div class="block2">
			<img src="../../../assets/image/mobile.png" alt="" style="position: absolute; top: 124px; left: 1006px;">
			<div class="text1">
				壹本账管理系统
			</div>
			<div class="text2">
				手机端
			</div>
			<div class="text3">
				不受时间和空间的限制，随时随地可以办公。
			</div>
			
			<div class="text4">
				<li>老板：通过微信小程序，随时掌控公司经营盈亏、资金收支、往来款、库存等经营状况，
				<br>解放老板、让管理更简单！</li>
			</div>
			
			<div class="text4" style="top: 344px;">
				<li>员工：移动办公，可以直接通过手机端进行费用报销、订单处理（销售订单和采购订单）。 </li>
			</div>
			
			<img src="../../../assets/image/minicode.png" alt="" style="position: absolute; top: 402px; left: 210px;">
			
			<div class="text4" style="left: 218px; top: 536px; font-size: 16px;">
				微信扫码体验
			</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "download",
		data() {
			return {
				buttontext:"我要咨询"
			}
		},
		methods: {
			buttonclick(str) {
				if (str == "cloud") {
					window.location = "https://download.s21i.faiusr.com/14807269/0/0/ABUIABBLGAAg9cT2jAYo3JuGiwM.exe?f=BookOneCloud5.4.1.0.exe&v=1637720695";
				} else if (str == "local") {
					window.location = "https://download.s21i.faiusr.com/14807269/0/0/ABUIABBLGAAgvcj2jAYo1OqqqQY.exe?f=BookOneLocal5.4.1.0.exe&v=1637721152";
				}
			},
			buttonEnter() {
				this.buttontext = "服务热线：0731-52413998";
			},
			buttonLeave() {
				this.buttontext = "我要咨询";
			},
		}
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		
		.block {
			width: 1920px;
			height: 873px;
			background: linear-gradient(0deg, #D10000 0%, #FF0000 100%);
			position: absolute;
			
			.text1 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 107px;
				left: 210px;
			}
			
			.text2 {
				width: 102px;
				height: 40px;
				background: #FFFFED;
				border-radius: 10px;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 40px;
				text-align: center;
				position: absolute;
				top: 120px;
				left: 573px;
			}
			
			.text3 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 177px;
				left: 210px;
			}
			
			.text4 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 26px;
			}
			
			.box1 {
				width: 250px;
				height: 72px;
				background: #FFFFFF;
				border-radius: 36px;
				position: absolute;
				left: 210px;
				top: 429px;
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				
				.text5 {
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #FF0000;
				}
				
				.text6 {
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FF0000;
				}
			}
			
			.box1:hover {
				width: 250px;
				height: 72px;
				background: linear-gradient(0deg, #FF0000 0%, #FF7E00 100%);
				box-shadow: 4px 2px 7px 1px rgba(199,24,0,0.77);
				border-radius: 36px;
				
				.text5 {
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #FFFFFF;
				}
				
				.text6 {
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
				}
			}
			
			.box2 {
				width: 1495px;
				height: 247px;
				background: linear-gradient(0deg, #FF0000 0%, rgba(255,255,255,0) 100%);
				box-shadow: 8px 4px 20px 4px #D20000;
				opacity: 0.35;
				border-radius: 20px;
				position: absolute;
				top: 557px;
				left: 210px;
			}
			
			.box3 {
				width: 1495px;
				height: 247px;
				border-radius: 20px;
				position: absolute;
				top: 557px;
				left: 210px;
			}
			
			.button1 {
				width: 180px;
				height: 50px;
				background: #FFFFFF;
				border-radius: 25px;
				position: absolute;
				left: 30px;
				top: 154px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FF0000;
			}
			
			.button1:hover {
				width: 354px;
				height: 50px;
				background: linear-gradient(0deg, #FF0000 0%, #FF7E00 100%);
				box-shadow: 4px 2px 7px 1px rgba(199,24,0,0.77);
				border-radius: 25px;
				font-size: 18px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
		
		.block2 {
			width: 1920px;
			height: 634px;
			background: #FF6D00;
			position: absolute;
			top: 973px;
			
			.text1 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 107px;
				left: 210px;
			}
			
			.text2 {
				width: 102px;
				height: 40px;
				background: #FFFFED;
				border-radius: 10px;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 40px;
				text-align: center;
				position: absolute;
				top: 120px;
				left: 573px;
			}
			
			.text3 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 177px;
				left: 210px;
			}
			
			.text4 {
				font-size: 20px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 30px;
				position: absolute;
				top: 270px;
				left: 210px;
			}
		}
	}
</style>